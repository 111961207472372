@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
    background-color: whitesmoke;
    padding: 5px;
}

/* Adding Standardized Card Sizes (Height) */
/* .card-small {
    height: 200px;
  }
  
  .card-medium {
    height: 500px;
  }
  
  .card-large {
    height: 400px;
  }
  
  .card-extra-large {
    height: 500px;
  } */

  /* ===== QuickLinks Control Panel ====== */
  .control-panel {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 5px;
    /* background: #f5f5f5;  */
    border-radius: 12px; 
    margin-top: 5px !important;
    margin-bottom: -15px !important;
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);  */
  }
  
  .control-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px; 
    height: 40px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .control-button img {
    width: 25px;
    height: 25px;
  }
  
  .control-button:hover {
    background: #e0f7fa; 
    border-color: #00acc1; 
    transform: translateY(-5px); /* Lift Effect -- LOVE THIS */
    box-shadow: 0px 4px 10px rgba(0, 172, 193, 0.3);
  }

/* ============ ================== ============ */

.dashboard-selectors-container {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    align-items: flex-start;
    flex-grow: 1;
    flex-basis: 0;
}

.snapshot-control-container {
    margin-bottom: 15px;
}

.header-container-card {
    box-shadow: 1px 4px 4px black;

}

.secondary-dashboard-selectors-portfolio {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 1.5rem;
}

.portfolio-view-selectors-container {
    margin-bottom: 1.5rem;
}

.project-information-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -10%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.project-links-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
    flex-grow: 1;
    flex-basis: 0;
}


.header-card-title {
    color: white;
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal;
    max-height: 30px !important;
    margin-bottom: 5px !important;
    font-size: larger !important;
}
.red-datawarehouse-banner {
    font-family: "Titillium Web", sans-serif !important;
    color: red;
    border: 1px solid red !important;
    text-align: center;
    padding: 10px 0;
    font-size: 18px;
    width: 300px;
    margin: 0 auto;
    z-index: 10;
    opacity: 70%;
    border-radius: 10px;
  }
  .banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
  }

.real-estate-banner {
    font-family: "Titillium Web", sans-serif !important;
    color: red; 
    border: 1px solid red !important;
    text-align: center; 
    padding: 10px 0;
    font-size: 18px;
    width: 200px;
    position: relative; 
    top: 50%;
    margin-left: auto;
    margin-right: auto;
    z-index: 10; 
    opacity: 70%;
    border-radius: 10px;
    margin-top: -15px;
}

.centered-text {
    font-family: "Titillium Web", sans-serif !important;
    display: flex;
    text-align: center;
    text-align: center; 
    font-size: 18px;
    width: 70vw;
    top: 50%;
    margin-left: auto;
    margin-right: auto;
    opacity: 100%;
}

.italic-font {
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
    font-style: italic;
}

.titillium-web-base {
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
}

.titillium-web {
    font-family: "Titillium Web", sans-serif !important;
}

.project-team-typography {
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
    font-size: 20px !important;
}

.card-title {
    color: #003865;
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal;
    max-height: 25px !important;
    margin-bottom: 5px !important;
    font-size: larger !important;
}

.key-project-info-body {
    color: black;
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal;
    max-height: 18px !important;
    font-size: 18px !important;
}
.key-project-info-error {
    color: red;
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 300 !important;
    font-style: normal;
    max-height: 15px !important;
    font-size: 15px !important;
}


.card-title-financials {
    color: #003865;
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal;
    max-height: 25px !important;
    margin-bottom: 5px !important;
    font-size: larger !important;
    text-align: center;
}

.key-project-info-financials {
    color: black;
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal;
    max-height: 18px !important;
    font-size: 18px !important;
    text-align: center;
}

.main-key-project-info-body {
    color: black;
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal;
    max-height: 15px !important;
    font-size: 20px !important;
    margin-bottom: 20px !important;
}
.key-project-info-sf {
    color: black;
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal;
    max-height: 15px !important;
    font-size: 22px !important;
}
.ppsf-container {
    display: flex;
    flex-direction: column;
    grid-gap: '15px';
    gap: '15px'
}
.ppsf-mini {
    color: black;
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal;
    max-height: 15px !important;
    font-size: 12px !important;
    margin-bottom: -10px !important;
    margin-top: -10px !important;
    
}

.title-block-content {
    display: flex;
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: #093d61;
    border-radius: 15px;
}

.project-account-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.dashboard-title {
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
}

.navigation-links {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    grid-gap: 10px;
    gap: 10px;
}

.nav-link {
    text-decoration: underline !important;
    color: white !important;
}

.nav-link:hover {
    opacity: 10%;
}

.card-content-container {
    box-shadow: 5px 5px 5px black;
    border-radius: 10px !important;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.card-content {
    background-color: white;
    width: 100%;
}


.project-icons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: -9px;
    margin-top: 6px;
    margin-bottom: -5px;
    width: 100%;
    align-items: center;
    grid-gap: 13px;
    gap: 13px;
}

.project-link-icon {
    width: 25px;
    height: 25px;
    transition: transform 0.3s ease;
}

.project-link-icon:hover {
    transform: scale(1.2);
}

.bonus-tool-container {
    display: flex;
    flex-direction: row;
    grid-gap: 2px;
    gap: 2px;
    justify-content: flex-end;
    align-items: center;
}

.bonus-tool-icon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    transition: transform 0.3s ease;   
}

.bonus-tool-icon:hover {
    transform: scale(1.2);
}

.inset-boxshadow {
    position: relative;  
    box-shadow: inset 25px 0 0 0 green;
}

/* Percent Complete */
.percent-complete-spinner {
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
    text-align: center;
    font-size: 25px !important;
    opacity: .85 !important;
}

/* Table CSS */
.table-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-template-rows: repeat(3, 1fr); /* 3 rows */
    width: 300px;
    height: 200px; 
    border-collapse: collapse;
  }
  
  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-top: 1px solid black;
    border-left: 1px solid black;
  }
  
  /* Removing the outer borders */
  .table-container .cell:nth-child(-n+2) {
    border-top: none;
  }
  
  .table-container .cell:nth-child(2n+1) {
    border-left: none;
  }

  .footer-content {
    background-color: #093d61;
    color: white;
    padding: 20px 0;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 100%;
    margin-top: auto;
    text-align: center;
}

.map-display-address {
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
    margin-bottom: 12px !important;
}

.map-display-address:hover {
    color: #33bef2;
}

.overunder-width-control {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.payment-table-buttons-container {
    text-align: right;
    vertical-align: top;
}
.pdf-button {
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    padding: 3px 5px !important;
    font-size: 12px !important;
    cursor: pointer !important;
    margin-right: 5px !important;
    border-radius: 4px !important;
    transition: background-color 0.3s ease !important;
}
.lessons-learned-button {
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    padding: 3px 5px !important;
    font-size: 12px !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
}

.smaller-primary-button {
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    padding: 3px 8px !important;
    font-size: 12px !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
}

.smaller-primary-button:disabled {
    background-color: gray !important;
    color: #ccc !important; /* Lighter text color for disabled state */
    cursor: not-allowed !important; /* Indicates the button is disabled */
    opacity: 0.6 !important; /* Optional: Adds a faded effect */
}

.pagination-button-enabled {
    background-color: #007bff !important;
    color: white !important;
    border: 1px solid white !important;
    border-radius: 5px;
    padding: 6px 12px !important;
    font-size: 12px !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
}

.pagination-button-enabled:hover {
    background-color: #2c92ff !important;
}

.pagination-button-disabled {
    background-color: #cccccc;
    color: #666666;
    border: 1px solid #999999 !important;
    border-radius: 5px;
    padding: 6px 12px !important;
    font-size: 12px !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
}

.pdf-button:hover {
    background-color: #0056b3 !important;
}

.standard-primary-button {
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    padding: 10px 20px !important;
    font-size: 15px !important;
    cursor: pointer !important;
    margin-right: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    border-radius: 4px !important;
    transition: background-color 0.3s ease !important;
}

.smaller-primary-buttom {
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    padding: 3px 10px !important;
    font-size: 10px !important;
    cursor: pointer !important;
    margin-right: 4px !important;
    border-radius: 4px !important;
    transition: background-color 0.3s ease !important;
}

.outlined-primary-button {
    border: 2px solid #45b6e6 !important;
    color: #45b6e6 !important;
    padding: 2px 5px;
    font-size: 0.875rem;
    text-transform: uppercase;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.outlined-primary-button:hover {
      background-color: rgba(25, 118, 210, 0.08) !important;
      border-color: #1976d2 !important;
    }

.procore-button {
    background-color: #ff5200 !important;
    color: white !important;
    border: none !important;
    padding: 5px 10px !important;
    font-size: 15px !important;
    cursor: pointer !important;
    margin-right: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    border-radius: 4px !important;
    transition: background-color 0.3s ease !important;
}
.smaller-procore-button {
    background-color: #ff5200 !important;
    color: white !important;
    border: none !important;
    padding: 5px 12px !important;
    font-size: 12px !important;
    cursor: pointer !important;
    margin-right: 4px !important;
    border-radius: 4px !important;
    transition: background-color 0.3s ease !important;
}

.toggle-primary-button {
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    padding: 5px 10px !important;
    font-size: 15px !important;
    cursor: pointer !important;
    margin-right: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    border-radius: 4px !important;
    transition: background-color 0.3s ease !important;
}

.standard-secondary-button {
    background-color: grey !important;
    color: white !important;
    border: none !important;
    padding: 5px 10px !important;
    font-size: 12px !important;
    cursor: pointer !important;
    margin-right: 10px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    border-radius: 4px !important;
    transition: background-color 0.3s ease !important;
}

.smaller-secondary-button {
    background-color: grey !important;
    color: white !important;
    border: none !important;
    padding: 4px 11px !important;
    font-size: 12px !important;
    padding: 3px 10px !important;
    margin-right: 4px !important;
    border-radius: 4px !important;
    transition: background-color 0.3s ease !important;
}

.standard-primary-button:hover {
    background-color: #0056b3 !important;
}


.excel-button {
    background-color: #1e613b !important;
    color: white !important;
    border: none !important;
    padding: 3px 5px !important;
    font-size: 12px !important;
    cursor: pointer !important;
    margin-right: 5px !important;
    border-radius: 4px !important;
    transition: background-color 0.3s ease !important;
}

.excel-button:hover {
    background-color: #144d2a !important;
}



/* PORTFOLIO VIEW CSS */
.portfolio-view-control-btns-container {
    display: flex;
    width: 265px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    border: 0px solid black;
    border-radius: 5px;
}

.project-card {
    background-color: #093d61;
    border-radius: 10px;
}

.portfolio-card-header {
    color: white;
}

.portfolio-card-footer {
    color: white
}

.portfolio-component-card {
    background-color: white;
    border-radius: 5px;
    height: 120px;
    margin: 10px 0px;
}

.csr-report-link {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.csr-report-link:hover {
    color: white; 
    text-decoration: underline; 
}

.csr-report-link-container {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .progress-bar-label {
    width: 15%;
    margin-left: 12px;
    text-align: 'left';
}

.snapshot-selections-display {
    font-family: "Titillium Web", sans-serif !important;
    font-size: small !important;
}

/* USE TO MAKE HEADER STICKY -- WILL NEED TO REORGANIZE LAYOUT */
/* .header-container {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    margin-bottom: 100px;
} */

.select-dropdown-container {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    width: 250px;
}

.exclude-completed-label .MuiTypography-root {
    font-size: 16px;
  }

.portfolio-view-control-btns-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2px;
    min-width: 275px;
    margin-top: -10px;
}
/* ====================== */
/* ==== BVT Styling ===== */
/* ====================== */

.bvt-table-lineitem  {
    padding-left: 30px !important;
}

.bvt-table-total {
    border-top: thick double gray !important;
}

.bvt-table-total .MuiTableCell-root {
    font-weight: bold;
}

.bvt-table-total-b .MuiTableCell-root {
    font-weight: bold;
}

.bvt-table-title {
    color: #003865;
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal;
    max-height: 35px !important;
    margin-bottom: 5px !important;
    font-size: large !important;
    margin-top: 20px !important;
}

.warning-icon-tooltip-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.table-logo-button {
    max-width: 15%;
    max-height: auto;
    cursor: pointer;
}

.table-logo-button:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

/* Project Settings Module */
.data-control-headers {
    font-weight: 800 !important;
    font-size: 1rem;
    color: white !important;
}


@media only screen and (min-width: 1529px) {

    .selector-font-control .MuiInputLabel-root {
        font-size: medium;
    }
    .selector-font-control .MuiInputBase-input {
        font-size: medium; 
    }
    .select-dropdown-container {
        display: flex;
        flex-direction: column;
        grid-gap: 5px;
        gap: 5px;
        width: 190px;
    }
    .exclude-completed-label .MuiTypography-root {
        font-size: 13px;
      }
      .select-dropdown-container-portfolio {
        display: flex;
        flex-direction: column;
        grid-gap: 5px;
        gap: 5px;
        width: 225px;
    }
    .card-title {
        color: #003865;
        font-family: "Titillium Web", sans-serif !important;
        font-weight: 500 !important;
        font-style: normal;
        max-height: 25px !important;
        margin-bottom: 5px !important;
        font-size: medium !important;
    }
    .card-title-dashboard {
        color: #003865;
        font-family: "Titillium Web", sans-serif !important;
        font-weight: 500 !important;
        font-style: normal;
        max-height: 25px !important;
        margin-bottom: 5px !important;
        font-size: large !important;
    }
    .card-title-2 {
        color: white;
        font-family: "Titillium Web", sans-serif !important;
        font-weight: 500 !important;
        font-style: normal;
        max-height: 25px !important;
        margin-bottom: 5px !important;
        font-size: small !important;
    }
    .main-key-project-info-body {
        color: black;
        font-size: large !important;
    }
    .ppsf-mini {
        color: black;
        font-family: "Titillium Web", sans-serif !important;
        font-weight: 400 !important;
        max-height: 10px !important;
        font-size: 15px !important;
        margin-top: -10px !important;
        margin-bottom: -10px !important;
    }
    .pickups-and-busts-message-small {
        color: black;
        font-family: "Titillium Web", sans-serif !important;
        max-height: 10px !important;
        font-size: 10px !important;
    }
    .ppsf-container {
        display: flex;
        flex-direction: column;
    }
    .project-team-typography {
        font-family: "Titillium Web", sans-serif !important;
        font-weight: 500 !important;
        font-size: 15px !important;
    }
    .progress-bar-label {
        width: 15%;
        margin-left: 12px;
        text-align: 'left';
        font-size: 13px !important;
    }
   .project-header-title-text {
    font-size: medium !important;
   }

   .project-header-title-text-large {
    font-size: large !important;
   }

   .project-header-title-text-large-project {
    font-size: large !important;
    font-weight: 600 !important;
   }
   .project-header-title-text-small {
    font-size: small !important;
    font-weight: 600 !important;
   }

 
.portfolio-view-control-btns-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2px;
    min-width: 245px;
}
}

@media only screen and (min-width: 800px) and (max-width: 1529px) {
    .selector-font-control .MuiInputLabel-root {
        font-size: small;
    }
    .selector-font-control .MuiInputBase-input {
        font-size: small; 
    }
    .select-dropdown-container-portfolio {
        display: flex;
        flex-direction: column;
        grid-gap: 5px;
        gap: 5px;
        width: 225px;
    }
    .select-dropdown-container {
        display: flex;
        flex-direction: column;
        grid-gap: 2px;
        gap: 2px;
        width: 150px;
    }
    .select-dropdown-container-portfolio {
        width: 175px !important;
    }
    
  
    .exclude-completed-label .MuiTypography-root {
        font-size: 12px;
        grid-gap: 1px;
        gap: 1px;
      }
     
    .card-title {
        color: #003865;
        font-family: "Titillium Web", sans-serif !important;
        font-weight: 500 !important;
        font-style: normal;
        max-height: 25px !important;
        margin-bottom: 5px !important;
        font-size: smaller !important;
    }
    .card-title-2 {
        color: white;
        font-family: "Titillium Web", sans-serif !important;
        font-weight: 500 !important;
        font-style: normal;
        max-height: 25px !important;
        margin-bottom: 5px !important;
        font-size: smaller !important;
    }
    .main-key-project-info-body {
        color: black;
        font-size: medium !important;
    }
    .ppsf-mini {
        color: black;
        font-family: "Titillium Web", sans-serif !important;
        font-weight: 400 !important;
        max-height: 10px !important;
        font-size: 12px !important;
        margin-top: -10px !important;
        margin-bottom: -10px !important;
    }
    .stock-ticker {
        font-family: "Titillium Web", sans-serif !important;
        font-weight: 400 !important;
        max-height: 10px !important;
        font-size: 15px !important;
        margin-top: -10px !important;
        
    }
    .ppsf-container {
        display: flex;
        flex-direction: column;
    }
    .project-team-typography {
        font-family: "Titillium Web", sans-serif !important;
        font-weight: 500 !important;
        font-size: 15px !important;
    }
    .progress-bar-label {
        width: 15%;
        margin-left: 12px;
        text-align: 'left';
        font-size: 13px !important;
    }
   .project-header-title-text {
    font-size: small !important;
   }

   .project-header-title-text-large {
    font-size: medium !important;
   }
/* 
   .portfolio-view-control-btns-container {
    display: flex;
    width: 235px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    border: 0px solid black;
    padding: 5px;
    border-radius: 5px;
} */

.portfolio-view-control-btns-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2px;
    min-width: 245px;
}

.email-contact-button {
    display: none;
}

.select-dropdown-container-portfolio {
    display: flex;
    flex-direction: column;
    grid-gap: 1px;
    gap: 1px;
    width: 198px;
}
.dashboard-selectors-container {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: flex-start;
    flex-grow: 1;
    flex-basis: 0;
    height: 100%;
}
.secondary-dashboard-selectors-portfolio {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: 1rem;
}

.project-team-typography {
    font-family: "Titillium Web", sans-serif !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

}
.percent-change-subcontainer {
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

